<template>
  <div id="autoRendererTarget" />
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'

import { reloadNuxtApp } from '#app'
import { useI18n } from '#i18n'
import { definePageMeta, useAsyncData, useRoute, useSeoMeta } from '#imports'

import { Core, createMfxApp } from '@visiontree/mfx-auto-renderer'

import type r4 from 'fhir/r4'

import { useExternalStorage, SaveData } from '@/composables/useExternalStorage'
import { useSourcesStore } from '@/stores/sources'

definePageMeta({
  key(route) {
    return route.fullPath
  },
  layout: 'blank',
})

const { t } = useI18n()

useSeoMeta({ title: t('pages.preview.seoTitle') })

const route = useRoute()
const sources = useSourcesStore()
const externalStorage = useExternalStorage()

const localSource = computed(() => {
  return (
    sources.getById(String(route.query.src)) ||
    sources.getById(String(route.params.id))
  )
})

const { data } = await useAsyncData<SaveData | undefined>(() => {
  const localSource =
    sources.getById(String(route.query.src)) ||
    sources.getById(String(route.params.id))

  if (localSource) {
    return new Promise((resolve) => {
      resolve({
        key: localSource.id,
        config: localSource.config,
        sources: localSource.isValidJSON
          ? [JSON.parse(localSource.jsonString) as r4.Questionnaire]
          : [],
      })
    })
  }

  return externalStorage.get(String(route.params.id))
})

const mountMfxApp = () => {
  Core.loadConfig({
    ...data.value?.config,
    forms: {
      sources: (data.value?.sources as r4.Questionnaire[]) ?? [],
    },
  })

  createMfxApp().mount('#autoRendererTarget')
}

/**
 * If the source id is set in the URL then we won't reload changes automatically as this means that
 * the user is viewing the preview through an iframe and we can't reload the page without causing the
 * screen to flash. If there is no source id then it means we're in live-preview mode and want to reload
 * the config on change; since the auto-renderer doesn't support updating the config after initialization
 * we have to reload the page to get the changes to take effect.
 */
watch(localSource, (current, previous) => {
  if (
    route.query.disableReload ||
    (current && current?.jsonString === previous?.jsonString)
  ) {
    return
  }

  reloadNuxtApp({ force: true })
})

onMounted(() => {
  mountMfxApp()
})
</script>

<style>
/* Styling dependencies required by the auto-renderer */
@import url('@visiontree/mfx-auto-renderer/style.css');

[theme-mode='dark'] body {
  color: var(--bl-dk-text-color) !important;
  background-color: var(--bl-dk-background-color) !important;
}

[theme-mode='light'] body {
  color: var(--bl-text-color) !important;
  background-color: var(--bl-background-color) !important;
}
</style>
